import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { CheckCircle } from '@material-ui/icons'
import { graphql, PageProps } from 'gatsby'
import { getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import Layout from '../components/layout'

type BaedekerOriginalProps = { data: GatsbyTypes.OriginalPageQuery } & PageProps

const title = 'Baedeker.Das Original'
const description =
  'VORHER LESEN, WO ES NACHHER AM SCHÖNSTEN IST.\n' +
  'Das Original unter den Reiseführern setzt neue Maßstäbe in Sachen Inhalt, Design und Veredelung. Die bekannten Stärken bleiben: einmaliges Wissen, verlässliche Informationen, gewohnte Übersichtlichkeit'
const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  image: {
    width: '100%',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  centerGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
  },
}))

const BaedekerOriginal = ({
  location,
  data: { metaImage },
}: BaedekerOriginalProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const metaImageData =
    metaImage &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(metaImage)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { height, width } = metaImageData

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const imagePath = getSrc(metaImage)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('content')
    }
  }, [])
  return (
    <Layout
      location={location}
      seoProps={{
        title,
        description,
        images: [
          { height, width, path: imagePath ?? '', alt: '', caption: '' },
        ],
      }}
    >
      <Typography component="h1" variant="h2" gutterBottom>
        {title}
      </Typography>

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={8} lg={6} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/images/content/home/BAE_London_Homepage.png"
              layout="fullWidth"
              alt=""
            />
          </Grid>

          <Grid item xs={12} lg={6} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Unterhaltsam, hilfreich und gut aussehend.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Vorher lesen, wo es nachher am schönsten ist. Das Original unter
              den Reiseführern setzt neue Maßstäbe in Sachen Inhalt, Design und
              Veredelung. Die bekannten Stärken bleiben: einmaliges Wissen,
              verlässliche Informationen, gewohnte Übersichtlichkeit.
            </Typography>

            <List dense>
              {[
                'Detaillierte Reiseziel-Beschreibungen',
                'Alphabetisches Ordnungsprinzip',
                'Kompetente Sterne-Bewertungen',
                'Einzigartiges Produktdesign: haptisch und optisch ein Vergnügen',
                'Hochwertige Veredelung und Ausstattung',
                'Modernisiertes, harmonisches Layout',
                'Mit praktischer Easy-Zip-Karte zum einfachen aufklappen',
                'Rotes Gummiband als i-Tüpfelchen',
              ].map((text) => (
                <ListItem key={text}>
                  <ListItemIcon>
                    <CheckCircle style={{ color: green[500] }} />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid
            item
            xs={12}
            lg={8}
            className={classes.gridItem}
            style={{ order: lgUp ? 0 : 1 }}
          >
            <Typography component="h2" variant="h3" gutterBottom>
              &raquo;Das ist...&laquo;
            </Typography>

            <Typography variant="body1">
              Die großen Themen rund um die Destination: Inspirierende
              Hintergründe und neue Sichtweisen.
            </Typography>
          </Grid>

          <Grid item xs={8} lg={4} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/NEU-Das-ist-460x310.png"
              layout="fullWidth"
              alt=""
            />
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={8} lg={5} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/magische-momente-baedker-460x310.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>

          <Grid item xs={12} lg={7} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Magische Momente
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Kommen Sie zur rechten Zeit an den richtigen Ort und erleben Sie
              Unvergessliches.
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={8} lg={5} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/ueberraschende-erlebnisse.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>

          <Grid item xs={12} lg={7} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Überraschende Erlebnisse.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Entdecken Sie das Besondere, Orte zum Durchatmen und einfach
              Unbezahlbares.
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid
            item
            xs={12}
            lg={6}
            className={classes.gridItem}
            style={{ order: lgUp ? 0 : 1 }}
          >
            <Typography component="h2" variant="h3" gutterBottom>
              Touren & Karten
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Baedekers Vorschläge für Touren zu Fuß, mit dem Rad, mit dem Auto
              oder mit öffentlichen Verkehrsmitteln verbinden die schönsten und
              interessantesten Plätze. Zu jeder Tour gehört eine extra Karte.
            </Typography>
          </Grid>

          <Grid item xs={8} lg={6} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/Content_Touren_Karten-460x310.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ padding: lgUp ? 32 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 8 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid
            item
            xs={6}
            lg={3}
            className={classes.gridItem}
            style={{ order: lgUp ? 0 : 1 }}
          >
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/Content_Infografiken.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>

          <Grid item xs={12} lg={6} className={classes.centerGridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Infografiken und 3Ds
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Infografiken visualisieren Informationen, stellen Bezüge her und
              bieten Vergleiche zu spannenden Themen. 3D-Grafiken stellen die
              bekanntesten Sehenswürdigkeiten plastisch dar.
            </Typography>
          </Grid>

          <Grid item xs={6} lg={3} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/419-Indien-Taj-Mahal800-460x310.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}
export const originalPageQuery = graphql`
  query OriginalPage {
    metaImage: file(
      relativePath: { eq: "images/content/home/BAE_London_Homepage.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default BaedekerOriginal
